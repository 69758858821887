export default {
  // This is the list of languages your application supports
  supportedLngs: [
    "ar",
    "bg",
    "ca",
    "cs",
    "da",
    "de",
    "en",
    "es",
    "et",
    "fi",
    "fr",
    "hr",
    "hu",
    "it",
    "ja",
    "lt",
    "lv",
    "nl",
    "pl",
    "pt",
    "ro",
    "ru",
    "sk",
    "sv",
    "tr",
    "uk",
    "zh",
    "bn",
    "el",
    "he",
    "hi",
    "ko",
    "sq",
  ],
  // This is the language you want to use in case
  // if the user language is not in the supportedLngs
  fallbackLng: "en",
  // The default namespace of i18next is "translation", but you can customize it here
  // defaultNS: "translation",
};
